import { makeStyles } from "@material-ui/core";
const styles = makeStyles((theme) => ({
  h1: {
    fontSize: 24,
    margin: "3.125rem 0 2.625rem 0",
  },
  h2: {
    fontSize: 14,
    margin: "0",
    whiteSpace: "nowrap",
  },
  button: {
    width: "8.0625rem",

    marginRight: "1.5rem",
  },
}));
export default styles;
