/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addUser = /* GraphQL */ `
  mutation AddUser($input: addUserInput!) {
    addUser(input: $input)
  }
`;
export const addUserPython = /* GraphQL */ `
  mutation AddUserPython($input: addUserInput!) {
    addUserPython(input: $input)
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      name
      SKU
      color
      items
      price
      composition
      WHP
      RRP
      selection
      brand
      checkoutId
      checkout {
        id
        products {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      orderProduct {
        id
        orderId
        productId
        order {
          id
          status
          brand
          email
          totalQuantity
          totalPrice
          deliveryTerms
          createdAt
          updatedAt
          owner
        }
        product {
          id
          name
          SKU
          color
          items
          price
          composition
          WHP
          RRP
          selection
          brand
          checkoutId
          description
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        owner
      }
      description
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      name
      SKU
      color
      items
      price
      composition
      WHP
      RRP
      selection
      brand
      checkoutId
      checkout {
        id
        products {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      orderProduct {
        id
        orderId
        productId
        order {
          id
          status
          brand
          email
          totalQuantity
          totalPrice
          deliveryTerms
          createdAt
          updatedAt
          owner
        }
        product {
          id
          name
          SKU
          color
          items
          price
          composition
          WHP
          RRP
          selection
          brand
          checkoutId
          description
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        owner
      }
      description
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      name
      SKU
      color
      items
      price
      composition
      WHP
      RRP
      selection
      brand
      checkoutId
      checkout {
        id
        products {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      orderProduct {
        id
        orderId
        productId
        order {
          id
          status
          brand
          email
          totalQuantity
          totalPrice
          deliveryTerms
          createdAt
          updatedAt
          owner
        }
        product {
          id
          name
          SKU
          color
          items
          price
          composition
          WHP
          RRP
          selection
          brand
          checkoutId
          description
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        owner
      }
      description
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createSelection = /* GraphQL */ `
  mutation CreateSelection(
    $input: CreateSelectionInput!
    $condition: ModelSelectionConditionInput
  ) {
    createSelection(input: $input, condition: $condition) {
      id
      name
      SKU
      brand
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateSelection = /* GraphQL */ `
  mutation UpdateSelection(
    $input: UpdateSelectionInput!
    $condition: ModelSelectionConditionInput
  ) {
    updateSelection(input: $input, condition: $condition) {
      id
      name
      SKU
      brand
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteSelection = /* GraphQL */ `
  mutation DeleteSelection(
    $input: DeleteSelectionInput!
    $condition: ModelSelectionConditionInput
  ) {
    deleteSelection(input: $input, condition: $condition) {
      id
      name
      SKU
      brand
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createCheckout = /* GraphQL */ `
  mutation CreateCheckout(
    $input: CreateCheckoutInput!
    $condition: ModelCheckoutConditionInput
  ) {
    createCheckout(input: $input, condition: $condition) {
      id
      products {
        items {
          id
          name
          SKU
          color
          items
          price
          composition
          WHP
          RRP
          selection
          brand
          checkoutId
          description
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateCheckout = /* GraphQL */ `
  mutation UpdateCheckout(
    $input: UpdateCheckoutInput!
    $condition: ModelCheckoutConditionInput
  ) {
    updateCheckout(input: $input, condition: $condition) {
      id
      products {
        items {
          id
          name
          SKU
          color
          items
          price
          composition
          WHP
          RRP
          selection
          brand
          checkoutId
          description
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteCheckout = /* GraphQL */ `
  mutation DeleteCheckout(
    $input: DeleteCheckoutInput!
    $condition: ModelCheckoutConditionInput
  ) {
    deleteCheckout(input: $input, condition: $condition) {
      id
      products {
        items {
          id
          name
          SKU
          color
          items
          price
          composition
          WHP
          RRP
          selection
          brand
          checkoutId
          description
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createOrderProduct = /* GraphQL */ `
  mutation CreateOrderProduct(
    $input: CreateOrderProductInput!
    $condition: ModelOrderProductConditionInput
  ) {
    createOrderProduct(input: $input, condition: $condition) {
      id
      orderId
      productId
      order {
        id
        status
        brand
        email
        product {
          nextToken
        }
        totalQuantity
        totalPrice
        deliveryTerms
        createdAt
        updatedAt
        owner
      }
      product {
        id
        name
        SKU
        color
        items
        price
        composition
        WHP
        RRP
        selection
        brand
        checkoutId
        checkout {
          id
          createdAt
          updatedAt
          owner
        }
        orderProduct {
          id
          orderId
          productId
          createdAt
          updatedAt
          owner
        }
        description
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateOrderProduct = /* GraphQL */ `
  mutation UpdateOrderProduct(
    $input: UpdateOrderProductInput!
    $condition: ModelOrderProductConditionInput
  ) {
    updateOrderProduct(input: $input, condition: $condition) {
      id
      orderId
      productId
      order {
        id
        status
        brand
        email
        product {
          nextToken
        }
        totalQuantity
        totalPrice
        deliveryTerms
        createdAt
        updatedAt
        owner
      }
      product {
        id
        name
        SKU
        color
        items
        price
        composition
        WHP
        RRP
        selection
        brand
        checkoutId
        checkout {
          id
          createdAt
          updatedAt
          owner
        }
        orderProduct {
          id
          orderId
          productId
          createdAt
          updatedAt
          owner
        }
        description
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteOrderProduct = /* GraphQL */ `
  mutation DeleteOrderProduct(
    $input: DeleteOrderProductInput!
    $condition: ModelOrderProductConditionInput
  ) {
    deleteOrderProduct(input: $input, condition: $condition) {
      id
      orderId
      productId
      order {
        id
        status
        brand
        email
        product {
          nextToken
        }
        totalQuantity
        totalPrice
        deliveryTerms
        createdAt
        updatedAt
        owner
      }
      product {
        id
        name
        SKU
        color
        items
        price
        composition
        WHP
        RRP
        selection
        brand
        checkoutId
        checkout {
          id
          createdAt
          updatedAt
          owner
        }
        orderProduct {
          id
          orderId
          productId
          createdAt
          updatedAt
          owner
        }
        description
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      status
      brand
      email
      product {
        items {
          id
          orderId
          productId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      totalQuantity
      totalPrice
      deliveryTerms
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      status
      brand
      email
      product {
        items {
          id
          orderId
          productId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      totalQuantity
      totalPrice
      deliveryTerms
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      status
      brand
      email
      product {
        items {
          id
          orderId
          productId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      totalQuantity
      totalPrice
      deliveryTerms
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateRequest = /* GraphQL */ `
  mutation UpdateRequest(
    $input: UpdateRequestInput!
    $condition: ModelRequestConditionInput
  ) {
    updateRequest(input: $input, condition: $condition) {
      id
      name
      surname
      email
      website
      phone
      store
      department
      status
      createdAt
      updatedAt
    }
  }
`;
export const deleteRequest = /* GraphQL */ `
  mutation DeleteRequest(
    $input: DeleteRequestInput!
    $condition: ModelRequestConditionInput
  ) {
    deleteRequest(input: $input, condition: $condition) {
      id
      name
      surname
      email
      website
      phone
      store
      department
      status
      createdAt
      updatedAt
    }
  }
`;
export const createRequest = /* GraphQL */ `
  mutation CreateRequest(
    $input: CreateRequestInput!
    $condition: ModelRequestConditionInput
  ) {
    createRequest(input: $input, condition: $condition) {
      id
      name
      surname
      email
      website
      phone
      store
      department
      status
      createdAt
      updatedAt
    }
  }
`;
