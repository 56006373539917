import React, { useState } from "react";
import { Formik, Form } from "formik";
import { Button, Grid, Box, Typography } from "@material-ui/core";
import { API } from "aws-amplify";
import FormColumn from "../FormColumn";
import InputField from "../InputField";
import styles from "./styles";
import { createRequest } from "../../graphql/mutations";
import { RequestAccessSchema } from "src/utils/validation";
import Analytics from "@aws-amplify/analytics";

const RequestAccesForm = () => {
  const classes = styles();
  const [isRequested, setIsRequested] = useState(false);
  return (
    <Formik
      initialValues={{
        email: "",
        name: "",
        surname: "",
        phone: "",
        store: "",
        website: "",
        department: "",
      }}
      validationSchema={RequestAccessSchema}
      onSubmit={async (values) => {
        values.email = values.email.toLowerCase();
        try {
          await API.graphql({
            query: createRequest,
            variables: { input: values },
            authMode: "AWS_IAM",
          });
          setIsRequested(true);
          Analytics.record("request-access");
        } catch (err) {
          console.log(err);
        }
      }}
    >
      {({ submitForm, isSubmitting }) =>
        isRequested ? (
          <Typography>
            THANK YOU FOR YOUR REQUEST <br /> WE WILL SEND YOU AN EMAIL WITH
            LOGIN INFORMATION <br />
            HAVE A GOOD DAY
          </Typography>
        ) : (
          <Form>
            <h1 className={classes.h1}>REQUEST ACCESS</h1>
            <Grid container direction="row" wrap="nowrap" spacing={10}>
              <FormColumn title="CONTACT">
                <InputField label="Name" name="name" />
                <InputField label="Surname" name="surname" />
                <InputField label="Email" name="email" />
                <InputField label="Phone number" name="phone" />
              </FormColumn>

              <FormColumn title="STORE INFO">
                <InputField label="Store name" name="store" />
                <InputField label="Website" name="website" />
                <InputField label="Department" name="department" />
              </FormColumn>
            </Grid>
            <Box m="5rem 0 15.625rem 0">
              <Button
                onClick={submitForm}
                className={classes.button}
                type="submit"
                disabled={isSubmitting}
              >
                Save
              </Button>
            </Box>
          </Form>
        )
      }
    </Formik>
  );
};

export default RequestAccesForm;
